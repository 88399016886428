.po_items_ho {
    column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 3rem;
    display:flex;  
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4rem;
}

.po_item {
    flex: 0 0 calc(33.33% - 1rem - 2rem);
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
    min-width: 300px;
}

.po_item img {
    max-width: 100%;
    max-height: 100%;
}


.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}

@media (max-width: 1250px) {
    .po_items_ho {
        flex-wrap: wrap;
        column-width: auto;
        -moz-column-width: auto;
        -webkit-column-width: auto;
        column-gap: initial;
        -moz-column-gap: initial;
        -webkit-column-gap: initial;
    }

    .po_item {
        flex: 1 1 100%;
        margin: 25px;
    }
}

.tags {
    margin-top: 10px;
}

.tag {
    display: inline-block;
    padding: 4px 8px;
    margin-right: 5px;
    background-color: var(--tag-color);
    color: var(--tag-text-color);
    font-size: 14px;
}

.po_item .content .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.po_item .content .tag {
    background-color: var(--secondary-color);
    color: black;
    font-size: 12px;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
}

.po_item .content .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.po_item .content .button-group a {
    flex-grow: 1;
    margin: 0 0.25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.last-item {
    margin-bottom: 60px;
}